import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

const variantStyles = {
  positive: `bg-general-positive text-content-positive`,
  negative: `bg-general-negative text-content-negative`,
  warning: `bg-general-warning text-content-warning`,
  informative: `bg-general-informative text-content-informative`,
  neutral: `bg-general-neutral-light text-content-secondary`,
};

type ToastProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  variant?: keyof typeof variantStyles;
  onClose?: () => void;
  className?: string;
  isVisible?: boolean;
};

function ToastIcon({ variant }: { variant: ToastProps['variant'] }) {
  if (variant === 'positive') {
    return <CheckCircleIcon />;
  } else if (variant === 'negative') {
    return <XCircleIcon />;
  } else if (variant === 'warning') {
    return <ExclamationTriangleIcon />;
  } else if (variant === 'informative') {
    return <InformationCircleIcon />;
  } else {
    return <QuestionMarkCircleIcon />;
  }
}

export default function Toast({
  children,
  variant = 'informative',
  onClose,
  className,
  isVisible = true,
  ...props
}: ToastProps) {
  return (
    <div
      className={clsx(
        `p-2 flex items-start gap-x-2 rounded-lg transition-all opacity-0 translate-y-1`,
        isVisible && `opacity-100 translate-y-0 animate-toast-in`,
        variantStyles[variant],
        className,
      )}
      {...props}
    >
      <div className="flex-[0_0_1rem] w-4 h-4 mt-[1px]">
        <ToastIcon variant={variant} />
      </div>
      <div className="text-body-md text-content-primary">{children}</div>
      {onClose && (
        <button
          className="bg-transparent cursor-pointer text-content-primary hover:bg-content-primary/10 rounded-sm -mt-[3px] -mb-[3px]"
          onClick={onClose}
        >
          <XMarkIcon className="w-6 h-6" />
        </button>
      )}
    </div>
  );
}
