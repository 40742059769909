import { ReactNode, ReactElement, cloneElement } from 'react';
import Label, { LabelSize } from '../Label';
import ErrorMessage from '../ErrorMessage';
import InfoMessage from '../InfoMessage';

type FieldWrapperProps = {
  // The input are rendering. Can be anything, but generally another input from the React Component library
  input: ReactElement;
  className?: string;
  // Passed to the input
  name: string;
  required?: boolean;
  // Display props
  label?: string;
  description?: string;
  error?: ReactNode;
  info?: ReactNode;
  labelSize?: LabelSize;
};

export default function FieldWrapper({
  className,
  label,
  description,
  error,
  info,
  required,
  name,
  input,
  labelSize = 'md',
}: FieldWrapperProps) {
  return (
    <div className={className}>
      <Label htmlFor={name} labelSize={labelSize} className="block mb-2">
        {label}
        {required && <span className="text-content-negative">*</span>}
      </Label>
      {description && <p className="text-body-md mb-1 text-content-secondary">{description}</p>}
      {cloneElement(input, { name, id: name, inputId: name, required })}
      {info && <InfoMessage className="mt-1">{info}</InfoMessage>}
      {error && <ErrorMessage className="peer-invalid:flex mt-1">{error}</ErrorMessage>}
    </div>
  );
}
