import clsx from 'clsx';
import { LabelHTMLAttributes, ReactNode } from 'react';

const sizeStyles = {
  sm: `text-body-md`,
  md: `text-body-lg`,
};

export type LabelSize = keyof typeof sizeStyles;

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  className?: string;
  children: ReactNode;
  labelSize?: LabelSize;
};

const sharedStyles = `text-content-primary font-body-lg`;

export default function Label({ children, labelSize = 'md', className, ...props }: LabelProps) {
  return (
    <label {...props} className={clsx(sharedStyles, sizeStyles[labelSize], className)}>
      {children}
    </label>
  );
}
