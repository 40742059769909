import { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

export type RadioInputProps = InputHTMLAttributes<HTMLInputElement> & {
  sizeVariant?: keyof typeof sizeStyles;
};

const sizeStyles = {
  sm: `flex-[0_0_16px] w-4 h-4`,
  md: `flex-[0_0_24px] w-6 h-6`,
};

const inputStyles = `hidden`;
const radioStyles = `
transition-colors relative bg-interaction-fill-enabled border border-interaction-outline-enabled shadow-light-down rounded-full
peer-active:hover:border-interaction-outline-hover
peer-checked:border-6 peer-checked:border-action-fill-primary-enabled hover:peer-checked:border-action-fill-primary-hover 
peer-invalid:border-action-outline-negative-secondary-enabled
peer-disabled:bg-action-content-secondary-disabled peer-disabled:shadow-none peer-disabled:border-interaction-outline-disabled
peer-disabled:hover:bg-action-content-secondary-disabled peer-disabled:hover:border-interaction-outline-disabled
`;

export default function RadioInput({ className, sizeVariant = 'md', ...props }: RadioInputProps) {
  return (
    <>
      <input {...props} className={clsx('peer', inputStyles)} type="radio" />
      <div
        className={clsx(radioStyles, sizeStyles[sizeVariant], className)}
        role="radio"
        aria-checked={props.checked}
        tabIndex={0}
      />
    </>
  );
}
