import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { HTMLAttributes, ReactNode } from 'react';
import Button from '../Button';
import ButtonLink from '../Button/ButtonLink';
import clsx from 'clsx';

const variantStyles = {
  positive: `bg-general-positive text-content-positive`,
  negative: `bg-general-negative text-content-negative`,
  warning: `bg-general-warning text-content-warning`,
  informative: `bg-general-informative text-content-informative`,
  neutral: `bg-general-neutral-light text-content-secondary`,
};

type InfoboxProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
  title?: string;
  variant?: keyof typeof variantStyles;
  onAction?: () => void;
  actionHref?: string;
  actionText?: string;
  className?: string;
};

function InfoboxIcon({ variant }: { variant: InfoboxProps['variant'] }) {
  if (variant === 'positive') {
    return <CheckCircleIcon />;
  } else if (variant === 'negative') {
    return <XCircleIcon />;
  } else if (variant === 'warning') {
    return <ExclamationTriangleIcon />;
  } else if (variant === 'informative') {
    return <InformationCircleIcon />;
  } else {
    return <QuestionMarkCircleIcon />;
  }
}

export default function Infobox({
  children,
  title,
  variant = 'informative',
  onAction,
  actionHref,
  actionText,
  className,
  ...props
}: InfoboxProps) {
  return (
    <div
      className={clsx(
        `p-2 flex items-start gap-x-2 rounded-lg transition-all opacity-100 translate-y-1`,
        variantStyles[variant],
        className,
      )}
      {...props}
    >
      <div className="flex-[0_0_1rem] w-4 h-4 mt-[1px]">
        <InfoboxIcon variant={variant} />
      </div>
      <div className="flex flex-1 flex-col gap-y-1">
        {title && <div className="text-body-md text-content-primary">{title}</div>}
        {children && <div className="text-body-md text-content-secondary">{children}</div>}
      </div>
      {actionHref && (
        <ButtonLink href={actionHref} variant="secondary" className="self-center" target="_blank">
          {actionText}
        </ButtonLink>
      )}
      {onAction && (
        <Button variant="secondary" onClick={onAction} className="self-center">
          {actionText}
        </Button>
      )}
    </div>
  );
}
