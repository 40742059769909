import { default as ReactDatePicker, DatePickerProps as ReactDatePickerProps } from 'react-datepicker';

import './styles.css';

export default function DatePickerInput(props: ReactDatePickerProps) {
  return (
    <ReactDatePicker
      {...props}
      id={props.id}
      /**
       * React-datepicker makes two divs next to eachother, and doesn't have a proper "wrapper" element around it.
       * To save a bit of effort with our custom CSS (and avoid making a bunch of custom elements), we just use the same
       * className for both.
       */
      wrapperClassName="eurekaDatePicker"
      calendarClassName="eurekaDatePicker"
    />
  );
}
