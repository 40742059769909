import clsx from 'clsx';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

type RadioGroupItem<T> = {
  title: string;
  value: T;
  badge?: ReactNode;
};

type RadioGroupProps<T> = {
  items: RadioGroupItem<T>[];
  name: string;
  wrapperClassName?: string;
  onChange: (value: RadioGroupItem<T>) => void;
  value: T;
  size?: keyof typeof sizeStyles;
};

const sizeStyles = {
  // Custom height values, to account for the border sizing
  lg: `h-[38px] px-3 text-button-lg`,
  md: `h-[34px] px-2 text-button-md`,
};

type BadgeProps = {
  active?: boolean;
  children: ReactNode;
};

function Badge({ active = false, children }: BadgeProps) {
  return (
    <div
      className={clsx(
        `ml-2 text-body-md border rounded-sm flex items-center px-1 h-6 [&_svg]:w-4 [&_svg]:h-4`,
        active &&
          `bg-action-fill-primary-enabled border-action-fill-primary-enabled text-action-content-primary-enabled`,
        !active &&
          `bg-general-neutral-light border-action-outline-secondary-enabled text-action-content-secondary-enabled`,
      )}
    >
      {children}
    </div>
  );
}

export default function RadioGroup<T>({
  items,
  name,
  wrapperClassName = '',
  value,
  onChange,
  size = 'md',
}: RadioGroupProps<T>): ReactElement {
  const activeIndex = items.findIndex(item => item.value === value);
  const [childWidths, setChildWidths] = useState<number[]>([]);
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const calculateWidths = () => {
      wrapperRef.current &&
        setChildWidths(
          [...wrapperRef.current.children].reduce((acc, child) => {
            child.getBoundingClientRect().width > 0 && acc.push(child.getBoundingClientRect().width);
            return acc;
          }, [] as number[]),
        );
    };

    calculateWidths();

    window.addEventListener('resize', calculateWidths);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', calculateWidths);
    };
  }, [items]);

  const handleOnChange = (item: RadioGroupItem<T>) => {
    onChange(item);
  };

  return (
    <div
      className={clsx(
        `
          bg-interaction-outline-enabled relative rounded-lg inline-block shadow-light-down
          has-[:focus-visible]:outline-2 has-[:focus-visible]:outline-interaction-outline-active/50
        `,
        wrapperClassName,
      )}
    >
      <div
        className={clsx(
          `absolute bg-action-content-link-enabled h-full transition-all motion-reduce:transition-none z-10 shadow-focus`,
          // First active on the first item, with no other items
          activeIndex === 0 && items.length === 1 && `rounded-r-lg`,
          // If we're active on the first element with more than 1 item
          activeIndex === 0 && items.length > 1 && `rounded-l-lg`,
          // If we're the active on the last index, with other items
          activeIndex === items.length - 1 && items.length > 1 && `rounded-r-lg`,
        )}
        style={{
          width: `${childWidths[activeIndex] + (activeIndex > 0 ? 1 : 0)}px`,
          left: childWidths.slice(0, activeIndex).reduce((total, width) => (total += width), 0),
          marginLeft: activeIndex > 0 ? '-1px' : '',
        }}
      />
      <div className="flex z-20 relative" ref={wrapperRef}>
        {items.map((item, index) => (
          <div key={`radio_group_${index}`} className="p-[1px]">
            <label
              htmlFor={`${name}_${index}`}
              className={clsx(
                sizeStyles[size],
                `flex items-center text-center text-action-content-secondary-enabled hover:bg-opacity-30 transition-colors`,
                // If we're the active label
                index === activeIndex && `bg-action-fill-secondary-active text-action-content-secondary-active!`,
                // All other labels
                index !== activeIndex && `bg-action-fill-secondary-enabled hover:bg-action-fill-secondary-hover`,
                // last label
                index === items.length - 1 && `rounded-r-[7px]`,
                // First label, with other items
                index === 0 && items.length > 1 && `rounded-l-[7px]`,
                // First label, with no other items
                index === 0 && items.length === 1 && `rounded-r-[7px]`,
                // Small position adjustment to deal with double width vertical border
                index > 0 && `-ml-[1px]`,
              )}
            >
              <input
                type="radio"
                name={name}
                id={`${name}_${index}`}
                onChange={() => handleOnChange(item)}
                checked={activeIndex === index}
                className="w-0 h-0 opacity-0"
              />
              {item.title}
              {item.badge !== undefined && <Badge active={index === activeIndex}>{item.badge}</Badge>}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
