import clsx from 'clsx';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';

export const sizeStyles = {
  md: `px-2 h-[32px]`,
  lg: `px-2 h-[40px]`,
};

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  wrapperClassName?: string;
  IconRight?: ReactNode;
  IconLeft?: ReactNode;
  sizeVariant?: keyof typeof sizeStyles;
};

const inputStyles = `
transition-colors bg-interaction-fill-enabled border border-interaction-outline-enabled rounded-lg w-full text-body-lg text-content-primary shadow-light-down
placeholder:text-body-lg placeholder:text-content-tertiary
hover:border-interaction-outline-hover hover:bg-interaction-fill-hover
focus:border-interaction-outline-active focus:bg-interaction-fill-active
disabled:border-interaction-outline-disabled disabled:bg-interaction-fill-disabled disabled:text-content-secondary disabled:shadow-none
disabled:placeholder:text-action-content-secondary-disabled
invalid:border-interaction-outline-negative hover:invalid:border-interaction-outline-negative
`;

const sharedIconStyles = `
absolute h-5 w-5 top-[9px]
[&_svg]:text-content-secondary [&_svg]:peer-disabled:text-action-content-secondary-disabled
`;

export default forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  { className, wrapperClassName, IconLeft, IconRight, sizeVariant = 'lg', ...props }: TextInputProps,
  ref,
) {
  return (
    <div className={clsx('relative', wrapperClassName)}>
      <input
        ref={ref}
        className={clsx(
          className,
          'peer',
          inputStyles,
          sizeStyles[sizeVariant],
          IconLeft && `pl-8`,
          IconRight && `pr-8`,
        )}
        {...props}
        id={props.id || props.name}
      />
      {IconLeft && <div className={clsx(sharedIconStyles, 'left-2')}>{IconLeft}</div>}
      {IconRight && <div className={clsx(sharedIconStyles, 'right-2')}>{IconRight}</div>}
    </div>
  );
});
