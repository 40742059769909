import { InputHTMLAttributes, ReactNode } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type ErrorMessageProps = InputHTMLAttributes<HTMLInputElement> & {
  children: ReactNode;
  className?: string;
};

export default function ErrorMessage({ children, className = '' }: ErrorMessageProps) {
  return (
    <p className={clsx('flex items-start gap-x-1 text-body-md text-content-secondary m-0', className)}>
      <ExclamationTriangleIcon className="text-content-negative w-4 h-4 flex-[0_0_16px] mt-[1px]" />
      {children}
    </p>
  );
}
