import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';
import {
  Dialog as HeadlessUiDialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
  Description,
  DialogProps,
  DialogBackdropProps,
  DialogTitleProps,
  DialogPanelProps,
  CloseButton,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';

type ModalProps = DialogProps & {
  children?: ReactNode;
  title?: string;
  panelClassName?: string;
};

const ModalComponents = {
  Dialog: function Dialog({ children, open, onClose, className, ...props }: DialogProps) {
    return (
      <HeadlessUiDialog open={open} onClose={onClose} className={clsx('relative z-50', className)} {...props}>
        {children}
      </HeadlessUiDialog>
    );
  },
  Backdrop: function Backdrop({ className, ...props }: DialogBackdropProps) {
    return (
      <DialogBackdrop
        className={clsx('fixed inset-0 bg-black/30 duration-300 ease-out data-closed:opacity-0', className)}
        transition
        {...props}
      />
    );
  },
  ContentWrapper: function ContentWrapper({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
    return (
      <div className={clsx('fixed inset-0 flex w-screen items-center justify-center p-2 lg:p-4', className)} {...props}>
        {children}
      </div>
    );
  },
  Title: function Title({ children, className, ...props }: DialogTitleProps) {
    return (
      <DialogTitle
        className={clsx(
          'flex items-center justify-between mx-6 mb-4 py-4 font-bold text-headline-h3 text-content-primary border-b border-general-neutral-light',
          className,
        )}
        {...props}
      >
        {children}
      </DialogTitle>
    );
  },
  Panel: function Panel({ children, className, ...props }: DialogPanelProps) {
    const hasActions =
      children &&
      Array.isArray(children) &&
      children.length === 2 &&
      Array.isArray(children[1]) &&
      (children[1] as ReactNode[]).some(child => (child as any)?.type?.name === 'Actions');

    return (
      <DialogPanel
        className={clsx(
          'max-w-3xl max-h-[75dvh] bg-white rounded-lg border border-general-neutral-light duration-300 ease-out data-closed:-translate-y-2 data-closed:opacity-0 shadow-dark-down grid grid-rows-[auto_1fr]',
          hasActions && 'grid-rows-[auto_1fr_auto]',
          className,
        )}
        transition
        {...props}
      >
        {children}
      </DialogPanel>
    );
  },
  Content: function Content({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
    return (
      <div className={clsx('px-6 mb-4 overflow-y-auto', className)} {...props}>
        {children}
      </div>
    );
  },
  Actions: function Actions({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
    return (
      <div
        className={clsx(
          'flex justify-between bg-general-neutral-lighter border-t border-outline-neutral-light py-4 px-6 rounded-b-lg overflow-hidden',
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
  Description: Description,
};

function Modal({ children, title, open, onClose, panelClassName, ...props }: ModalProps) {
  return (
    <ModalComponents.Dialog open={open} onClose={onClose} role="dialog" {...props}>
      <ModalComponents.Backdrop />
      <ModalComponents.ContentWrapper>
        <ModalComponents.Panel className={panelClassName}>
          <ModalComponents.Title>
            <span>{title}</span>
            <CloseButton className="flex-[0_0_1.5rem] hover:bg-black/5 rounded-sm">
              <XMarkIcon className="w-6 h-6 text-content-secondary" />
              <span className="sr-only">Close</span>
            </CloseButton>
          </ModalComponents.Title>
          {children}
        </ModalComponents.Panel>
      </ModalComponents.ContentWrapper>
    </ModalComponents.Dialog>
  );
}

Modal.Dialog = ModalComponents.Dialog;
Modal.Backdrop = ModalComponents.Backdrop;
Modal.Title = ModalComponents.Title;
Modal.Panel = ModalComponents.Panel;
Modal.Content = ModalComponents.Content;
Modal.Actions = ModalComponents.Actions;
Modal.Description = ModalComponents.Description;
Modal.ContentWrapper = ModalComponents.ContentWrapper;

export default Modal;
