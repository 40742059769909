import { ButtonHTMLAttributes, forwardRef } from 'react';
import ButtonContent, { BaseButtonProps, sharedStyles, variantStyles, sizeStyles } from './ButtonContent';
import clsx from 'clsx';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & BaseButtonProps;

export { variantStyles };

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { variant = 'primary', size = 'md', className, children, IconLeft, IconRight, ...props }: ButtonProps,
  ref,
) {
  return (
    <button ref={ref} {...props} className={clsx(sharedStyles, variantStyles[variant], sizeStyles[size], className)}>
      <ButtonContent IconLeft={IconLeft} IconRight={IconRight}>
        {children}
      </ButtonContent>
    </button>
  );
});
