import clsx from 'clsx';
import { HTMLAttributes, ReactNode, TableHTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from 'react';

type TableElementProps = {
  children?: ReactNode;
};

function Table({ children, className, ...props }: TableElementProps & TableHTMLAttributes<HTMLTableElement>) {
  return (
    <table className={clsx('w-full', className)} {...props}>
      {children}
    </table>
  );
}

Table.THead = function THead({
  children,
  className,
  ...props
}: TableElementProps & HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <thead className={clsx('[&>tr]:border-0', className)} {...props}>
      {children}
    </thead>
  );
};

Table.TBody = function TBody({
  children,
  className,
  ...props
}: TableElementProps & HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tbody
      className={clsx(
        `rounded-lg overflow-hidden
        shadow-[0_0_0_1px_rgba(217,224,237,1)]
        [&>tr:first-of-type>td:first-of-type]:rounded-tl-lg
        [&>tr:first-of-type>td:last-of-type]:rounded-tr-lg
        [&>tr:last-of-type>td:first-of-type]:rounded-bl-lg
        [&>tr:last-of-type>td:last-of-type]:rounded-br-lg
        `,
        className,
      )}
      {...props}
    >
      {children}
    </tbody>
  );
};

Table.TH = function TH({ children, className, ...props }: TableElementProps & ThHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th className={clsx('px-4 py-2 text-content-secondary text-body-md font-normal text-left', className)} {...props}>
      {children}
    </th>
  );
};

Table.TR = function TR({ children, className, ...props }: TableElementProps & HTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr
      className={clsx(
        'border-b border-outline-neutral-light last:border-0 odd:[&>td]:bg-general-neutral-lighter',
        className,
      )}
      {...props}
    >
      {children}
    </tr>
  );
};

Table.TD = function TD({ children, className, ...props }: TableElementProps & TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td className={clsx('px-4 py-2 h-16 text-content-primary text-body-lg', className)} {...props}>
      {children}
    </td>
  );
};

export default Table;
