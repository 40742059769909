import { TextareaHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

type TextInputProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

const inputStyles = `
transition-colors border bg-interaction-fill-enabled border-interaction-outline-enabled text-content-primary rounded-lg p-2 w-full text-body-lg shadow-light-down
placeholder:text-body-lg placeholder:text-content-tertiary
hover:bg-interaction-fill-active hover:border-interaction-outline-active
disabled:bg-interaction-fill-disabled disabled:border-interaction-outline-disabled disabled:text-action-content-secondary-disabled disabled:shadow-none
invalid:border-interaction-outline-negative hover:invalid:border-interaction-outline-negative
`;

export default forwardRef<HTMLTextAreaElement, TextInputProps>(function TextInput(
  { className, ...props }: TextInputProps,
  ref,
) {
  return <textarea ref={ref} className={clsx(inputStyles, className, 'peer')} {...props} id={props.id || props.name} />;
});
