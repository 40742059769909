import { ReactNode } from 'react';
import Label from '../Label';
import clsx from 'clsx';
import RadioInput, { RadioInputProps } from '../RadioInput';

type RadioFieldProps = RadioInputProps & {
  label: ReactNode;
  htmlFor?: string;
};

const radioWrapStyles = `flex items-center gap-x-2 font-normal`;

export default function RadioField({ className, label, sizeVariant = 'md', ...props }: RadioFieldProps) {
  return (
    <Label htmlFor={props.htmlFor} labelSize={sizeVariant} className={clsx(radioWrapStyles, className)}>
      <RadioInput {...props} sizeVariant={sizeVariant} />
      {label}
    </Label>
  );
}
