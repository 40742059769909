import Link, { LinkProps } from 'next/link';
import ButtonContent, { BaseButtonProps, sharedStyles, sizeStyles, variantStyles } from './ButtonContent';
import clsx from 'clsx';

export default function ButtonLink({
  variant = 'primary',
  size = 'md',
  children,
  IconRight,
  IconLeft,
  className,
  ...props
}: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & LinkProps & BaseButtonProps) {
  return (
    <Link className={clsx(className, sharedStyles, variantStyles[variant], sizeStyles[size])} {...props}>
      <ButtonContent IconLeft={IconLeft} IconRight={IconRight}>
        {children}
      </ButtonContent>
    </Link>
  );
}
