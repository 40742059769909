import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

export type LinkComponentProps = {
  page: number;
  children: React.ReactNode;
  label?: string;
  disabled?: boolean;
  className?: string;
  pathname: string;
};

type PaginationProps = {
  last: number;
  current: number;
  pathname: string;
  LinkComponent?: React.ComponentType<LinkComponentProps>;
};

// Padding around the currently active page
const PADDING = 3;
// Start and end padding (how many pages to display initially)
const START_END_PADDING = 5;
// When we want to start the pagination break
const PAGINATION_BREAK_START = 7;

type Page = {
  type: 'page';
  page: number;
  isCurrent: boolean;
};

type Spacer = {
  type: 'spacer';
};

// We want to always be showing 7 "tiles" on the pagination.
// When we're near the start, that will be the first 5 pages, a spacer, then the last page.
// When we're in the middle, it will be page 1, a spacer, the current page with a page either side, a spacer, then the last page.
// When we're near the end, it will be page 1, a spacer, then the last 5 pages.
// If we don't have enough pages to break 7 tiles, then we just show all the pages.
function calculatePages(last: number, current: number): Array<Page | Spacer> {
  // If we have only X pages, show all of them
  if (last <= PAGINATION_BREAK_START) {
    return Array.from({ length: last }, (_, i) => ({ type: 'page', page: i + 1, isCurrent: i + 1 === current }));
  }
  // Always show the first page
  const pages: Array<Page | Spacer> = [{ type: 'page', page: 1, isCurrent: current === 1 }];

  // If we're near-ish the start, put some padding after
  if (current < START_END_PADDING) {
    // Add the first few pages
    for (let i = 2; i <= START_END_PADDING; i++) {
      pages.push({ type: 'page', page: i, isCurrent: current === i });
    }

    pages.push({ type: 'spacer' });
  }
  // Or if we're near-ish the end, put some padding before
  else if (current > last - START_END_PADDING + 1) {
    pages.push({ type: 'spacer' });

    // Add the last few pages
    for (let i = last - START_END_PADDING + 1; i < last; i++) {
      pages.push({ type: 'page', page: i, isCurrent: current === i });
    }
  }
  // Or if we're in the middle, put some padding either side
  else {
    pages.push({ type: 'spacer' });

    // Add a few pages, starting before and finishing after the current page
    Array.from({ length: PADDING }, (_, i) => i - Math.floor(PADDING / 2)).forEach(i =>
      pages.push({ type: 'page', page: current + i, isCurrent: current === current + i }),
    );

    pages.push({ type: 'spacer' });
  }

  // Always show the last page
  pages.push({ type: 'page', page: last, isCurrent: current === last });

  return pages;
}

const pageButtonClass = `
  relative border rounded-lg h-8 w-7 sm:w-8 text-xs flex items-center justify-center shadow-light-down
  border-action-outline-secondary-enabled text-action-content-secondary-enabled bg-action-fill-secondary-enabled
`;
const pageButtonActiveClass = `
  text-action-content-secondary-active! bg-action-fill-secondary-active! border-action-outline-secondary-active! shadow-focus!
`;
const pageButtonHoverClass = `
  hover:border-action-outline-secondary-hover hover:text-action-content-secondary-hover hover:bg-action-fill-secondary-hover
`;
const pageButtonDisabled = `text-action-content-secondary-disabled bg-action-fill-secondary-disabled border-action-outline-secondary-disabled shadow-none pointer-events-none`;

// Note this code is un-needed for this component as it's expected that the user will provide a LinkComponent, but it provides a good reference
// implementation of how to use this component.
const generateUrl = (pathname: string, page: number) => {
  const params = new URLSearchParams(window.location.search);
  params.set('page', page.toString());
  return `${pathname}?${params.toString()}`;
};

const DefaultLinkComponent = ({ className, page, disabled = false, children, label, pathname }: LinkComponentProps) => (
  <a
    className={className}
    aria-disabled={disabled}
    aria-label={label || `Page ${page}`}
    href={disabled ? undefined : generateUrl(pathname, page)}
  >
    {children}
  </a>
);

export default function Pagination({ last, current, LinkComponent = DefaultLinkComponent, pathname }: PaginationProps) {
  const clampedCurrent = Math.min(Math.max(current, 1), last);
  const pages = calculatePages(last, clampedCurrent);

  return (
    <ul className="flex justify-center items-center gap-x-1 sm:gap-x-2">
      <li>
        <LinkComponent
          className={clsx(pageButtonClass, clampedCurrent === 1 ? pageButtonDisabled : pageButtonHoverClass, 'sm:mr-2')}
          disabled={clampedCurrent === 1}
          page={clampedCurrent - 1}
          label="Previous page"
          pathname={pathname}
        >
          <ChevronLeftIcon className="w-4 h-4" />
        </LinkComponent>
      </li>
      {pages.map((page, index) => (
        <li key={`pagination_${index}`}>
          {page.type === 'page' ? (
            <LinkComponent
              className={clsx(pageButtonClass, page.isCurrent ? pageButtonActiveClass : pageButtonHoverClass)}
              page={page.page}
              pathname={pathname}
            >
              {page.page}
            </LinkComponent>
          ) : (
            <div className={pageButtonClass}>
              <EllipsisHorizontalIcon className="w-4 h-4" />
            </div>
          )}
        </li>
      ))}
      <li>
        <LinkComponent
          className={clsx(
            pageButtonClass,
            clampedCurrent === last ? pageButtonDisabled : pageButtonHoverClass,
            'sm:ml-2',
          )}
          disabled={clampedCurrent === last}
          page={clampedCurrent + 1}
          label="Next page"
          pathname={pathname}
        >
          <ChevronRightIcon className="w-4 h-4" />
        </LinkComponent>
      </li>
    </ul>
  );
}
