import { HTMLAttributes, ReactNode } from 'react';
import {
  Dialog as HeadlessUiDialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
  Description,
  DialogProps,
  DialogBackdropProps,
  DialogTitleProps,
  DialogPanelProps,
  CloseButton,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type SidePanelProps = DialogProps & {
  children?: ReactNode;
  title?: ReactNode;
  panelClassName?: string;
};

const SidePanelComponents = {
  Dialog: function Dialog({ children, open, onClose, className, ...props }: DialogProps) {
    return (
      <HeadlessUiDialog open={open} onClose={onClose} className={clsx('relative z-50', className)} {...props}>
        {children}
      </HeadlessUiDialog>
    );
  },
  Backdrop: function Backdrop({ className, ...props }: DialogBackdropProps) {
    return (
      <DialogBackdrop
        className={clsx('fixed inset-0 bg-black/30 duration-300 ease-out data-closed:opacity-0', className)}
        transition
        {...props}
      />
    );
  },
  Title: function Title({ children, ...props }: DialogTitleProps) {
    return (
      <DialogTitle
        className="flex items-center justify-between mx-4 mb-4 py-4 font-bold text-headline-h2 text-content-primary border-b border-general-neutral-light"
        {...props}
      >
        {children}
      </DialogTitle>
    );
  },
  Panel: function Panel({ children, className, ...props }: DialogPanelProps) {
    const hasActions =
      children &&
      Array.isArray(children) &&
      children.length === 2 &&
      Array.isArray(children[1]) &&
      (children[1] as ReactNode[]).some(child => (child as any)?.type?.name === 'Actions');

    return (
      <DialogPanel
        className={clsx(
          'fixed top-0 right-0 w-dvw h-dvh md:w-96 bg-white border-l border-general-neutral-light duration-300 ease-out data-closed:translate-x-96 shadow-dark-down',
          hasActions && 'grid-rows-[64px_1fr_auto]',
          className,
        )}
        transition
        {...props}
      >
        {children}
      </DialogPanel>
    );
  },
  Content: function Content({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
    return (
      <div className={clsx('px-4 overflow-y-auto', className)} {...props}>
        {children}
      </div>
    );
  },
  Actions: function Actions({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
    return (
      <div className={clsx('px-4 py-4 w-full border-t border-outline-neutral-light', className)} {...props}>
        {children}
      </div>
    );
  },
  Description: Description,
};

function SidePanel({ children, title, open, onClose, panelClassName, ...props }: SidePanelProps) {
  return (
    <SidePanelComponents.Dialog open={open} onClose={onClose} role="dialog" {...props}>
      <SidePanelComponents.Backdrop />
      <SidePanelComponents.Panel className={panelClassName}>
        <SidePanelComponents.Title>
          <span>{title}</span>
          <CloseButton className="flex-[0_0_1rem] hover:bg-black/5 rounded">
            <XMarkIcon className="w-4 h-4 text-content-secondary" />
            <span className="sr-only">Close</span>
          </CloseButton>
        </SidePanelComponents.Title>
        {children}
      </SidePanelComponents.Panel>
    </SidePanelComponents.Dialog>
  );
}

SidePanel.Dialog = SidePanelComponents.Dialog;
SidePanel.Backdrop = SidePanelComponents.Backdrop;
SidePanel.Title = SidePanelComponents.Title;
SidePanel.Panel = SidePanelComponents.Panel;
SidePanel.Content = SidePanelComponents.Content;
SidePanel.Actions = SidePanelComponents.Actions;
SidePanel.Description = SidePanelComponents.Description;

export default SidePanel;
