import { InputHTMLAttributes } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

export type CheckboxInputProps = InputHTMLAttributes<HTMLInputElement> & {
  sizeVariant?: keyof typeof sizeStyles;
};

const sizeStyles = {
  sm: `rounded-sm flex-[0_0_16px] w-4 h-4`,
  md: `rounded-lg flex-[0_0_24px] w-6 h-6`,
};

const iconSizeStyles = {
  sm: 'w-3 h-3 top-[1px] left-[1px]',
  md: 'w-6 h-6 -top-[1px] -left-[1px]',
};

const inputStyles = `hidden`;
const checkboxStyles = `
transition-colors relative bg-interaction-fill-enabled border border-interaction-outline-enabled shadow-light-down
hover:border-interaction-outline-hover
peer-checked:bg-action-fill-primary-enabled hover:peer-checked:bg-action-fill-primary-hover peer-checked:[&_svg]:block 
peer-invalid:border-action-outline-negative-secondary-enabled
peer-disabled:bg-interaction-fill-disabled peer-disabled:shadow-none peer-disabled:border-interaction-outline-disabled peer-disabled:[&_svg]:text-action-content-secondary-disabled
hover:peer-disabled:bg-interaction-fill-disabled
`;

export default function CheckboxInput({ className, sizeVariant = 'md', ...props }: CheckboxInputProps) {
  return (
    <>
      <input {...props} className={clsx('peer', inputStyles)} type="checkbox" checked={props.checked} />
      <div
        className={clsx(checkboxStyles, sizeStyles[sizeVariant], className)}
        role="checkbox"
        aria-checked={props.checked}
        tabIndex={0}
      >
        <CheckIcon
          className={clsx(iconSizeStyles[sizeVariant], 'hidden absolute text-action-content-primary-enabled')}
        />
      </div>
    </>
  );
}
