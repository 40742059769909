import { InputHTMLAttributes, ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type InfoMessageProps = InputHTMLAttributes<HTMLInputElement> & {
  children: ReactNode;
  className?: string;
};

export default function InfoMessage({ children, className = '' }: InfoMessageProps) {
  return (
    <p className={clsx(className, 'flex items-start gap-x-1 text-body-md text-content-secondary m-0')}>
      <InformationCircleIcon className="text-content-informative w-4 h-4 flex-[0_0_16px] mt-[1px]" />
      {children}
    </p>
  );
}
