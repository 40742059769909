import { ReactNode } from 'react';
import Label from '../Label';
import clsx from 'clsx';
import CheckboxInput, { CheckboxInputProps } from '../CheckboxInput';

type CheckboxFieldProps = CheckboxInputProps & {
  label: ReactNode;
  htmlFor?: string;
};

const checkboxWrapStyles = `flex items-center gap-x-2 font-normal`;

export default function CheckboxField({ className, label, sizeVariant = 'md', ...props }: CheckboxFieldProps) {
  return (
    <Label htmlFor={props.htmlFor} labelSize={sizeVariant} className={clsx(checkboxWrapStyles, className)}>
      <CheckboxInput {...props} sizeVariant={sizeVariant} />
      {label}
    </Label>
  );
}
