import Logo from './Logo';

type Props = {
  linkUrl?: string;
  organisationName: string;
  strapline?: boolean;
  straplineLinkUrl?: string;
};

export function PoweredBy({ linkUrl, organisationName, strapline = false, straplineLinkUrl }: Props) {
  const logo = <Logo title={organisationName} />;

  return (
    <div className="flex items-stretch">
      {linkUrl ? (
        <a href={linkUrl} rel="noreferrer" target="_blank" className="block color-[#212529]">
          <span className="block mb-0.5 uppercase text-[10px] font-light">Powered By</span>
          {logo}
        </a>
      ) : (
        <span className="block color-[#212529]">
          <span className="block mb-0.5 uppercase text-[10px] font-light">Powered By</span>
          {logo}
        </span>
      )}
      {strapline ? (
        <span className="flex items-center max-w-55 border-l border-[#c0c0c0] pl-2.5 ml-2.5 text-[13px] leading-[16px] lg:max-w-450 lg:pl-5 lg:ml-5 lg:text-[17px] lg:leading-[22px]">
          <div>
            An online matchmaking platform for university-industry collaboration.{' '}
            <a
              href={straplineLinkUrl || linkUrl}
              target="_blank"
              rel="noreferrer"
              aria-label={`Learn more about ${organisationName}`}
            >
              Learn More
            </a>
          </div>
        </span>
      ) : null}
    </div>
  );
}
