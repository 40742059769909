
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  PaginationOptions,
  PaginationState,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import Table from './';
import Pagination, { LinkComponentProps } from '../Pagination';
import { ArrowDownIcon, ArrowPathIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type DataTableProps<T extends Record<string, any>> = {
  data: T[];
  columns: ColumnDef<T>[];
  pagination?: PaginationState;
  paginationOptions?: Pick<PaginationOptions, 'rowCount'>;
  PaginationLinkComponent?: React.ComponentType<LinkComponentProps>;
  isLoading?: boolean;
  showSpinner?: boolean;
  className?: string;
  noResultsMessage?: string;
  onSortingChange?: (sorting: SortingState) => void;
  sorting?: SortingState;
};

const DataTableSkeleton = () => (
  <>
    <div className="h-7 mb-1.5 w-full rounded-lg animate-pulse bg-general-neutral-light" />
    <div className="h-48 w-full rounded-lg animate-pulse bg-general-neutral-light" />
  </>
);

export default function DataTable<T extends Record<string, any>>({
  data = [],
  columns = [],
  pagination,
  paginationOptions,
  PaginationLinkComponent,
  sorting,
  onSortingChange,
  isLoading = false,
  showSpinner = false,
  className,
  noResultsMessage = 'No results found',
}: DataTableProps<T>) {
  const handleSortingChange = (updaterOrValue: Updater<SortingState>) => {
    if (onSortingChange) {
      const newSortingState = typeof updaterOrValue === 'function' ? updaterOrValue(sorting || []) : updaterOrValue;
      return onSortingChange(newSortingState);
    }
  };

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    ...paginationOptions,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: handleSortingChange,
    manualSorting: true,
    enableSortingRemoval: false,
  });

  if (isLoading) {
    return <DataTableSkeleton />;
  }

  if (!data.length) {
    return <div className="text-content-tertiary m-20 text-center">{noResultsMessage}</div>;
  }

  return (
    <>
      {/* A quirk of using shadows for the border, it isn't part of the width of the Table component
      so we need to add some 1px padding */}
      <div className={clsx(`overflow-x-auto p-[1px] max-w-[100vw] sm:max-w-full -mx-6 sm:mx-0 relative`, className)}>
        <Table className="mx-6 sm:mx-0 w-full">
          <Table.THead>
            {table.getHeaderGroups().map(headerGroup => (
              <Table.TR key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Table.TH key={header.id}>
                    <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>
                    {onSortingChange && header.column.getCanSort() && (
                      <button onClick={header.column.getToggleSortingHandler()} className="w-4 h-4 ml-1 pt-[3px]">
                        {
                          {
                            asc: <ArrowUpIcon />,
                            desc: <ArrowDownIcon />,
                            false: <ArrowsUpDownIcon />,
                          }[header.column.getIsSorted() as string]
                        }
                      </button>
                    )}
                  </Table.TH>
                ))}
              </Table.TR>
            ))}
          </Table.THead>
          <Table.TBody>
            {table.getRowModel().rows.map(row => (
              <Table.TR key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Table.TD key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.TD>
                ))}
              </Table.TR>
            ))}
          </Table.TBody>
        </Table>
        {showSpinner && (
          <div className="w-full h-full absolute top-0 left-0 bg-black/5 rounded-lg flex justify-center items-center">
            <ArrowPathIcon className="w-12 h-12 animate-spin" />
          </div>
        )}
      </div>

      {pagination && table.getPageCount() > 1 && (
        <div className="flex justify-end mt-4">
          <Pagination
            last={table.getPageCount()}
            current={table.getState().pagination.pageIndex + 1}
            pathname={window.location.pathname}
            LinkComponent={PaginationLinkComponent}
          />
        </div>
      )}
    </>
  );
}
