import React, { useState, useEffect, ReactElement } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { PoweredBy } from './PoweredBy';
import { ENV } from '@/constants';
import Logo from './Logo';
import { HomeIcon } from '@heroicons/react/24/solid';
import { ButtonLink } from '@inpart-io/react-components/src';

export const Layout: React.ElementType = ({ children }): ReactElement => {
  const { query, pathname } = useRouter();

  const campaignShortId = query.campaignId as string;
  const utmTags = {
    utm_source: campaignShortId ? 'discover-campaign' : 'discover-homepage',
    utm_medium: 'discover-public',
    utm_content: 'header',
    ...(campaignShortId ? { utm_campaign: campaignShortId } : {}),
  };

  const [returnTo, setReturnTo] = useState<string>('/');
  useEffect(() => {
    if (typeof window !== 'undefined' && pathname.startsWith('/discovercalls')) {
      setReturnTo(`${window.location.protocol}//${window.location.host}/discovercalls/${campaignShortId}`);
    }
  }, [pathname]);

  const loginUrl = `${ENV.NEXT_PUBLIC_COMPANIES_APP_URL}/login?userType=academic&returnTo=${returnTo}`;
  const registerUrl = `${
    ENV.NEXT_PUBLIC_COMPANIES_APP_URL
  }/register/academic?returnTo=${returnTo}&${new URLSearchParams(utmTags).toString()}`;

  return (
    <>
      <div className="h-[70px] bg-white flex items-center w-full shadow-light-down z-10 px-5 md:fixed md:top-0">
        <div className="mt-[5px] mr-5">
          <Link href="/" aria-label={`${ENV.NEXT_PUBLIC_ORGANISATION_NAME} home`}>
            <Logo title={`${ENV.NEXT_PUBLIC_ORGANISATION_NAME} Logo`} />
          </Link>
        </div>

        <div className="ml-auto flex items-center">
          <ButtonLink href={loginUrl} className="mr-2.5">
            Login
          </ButtonLink>
          <ButtonLink href={registerUrl} className="mr-2.5">
            Register
          </ButtonLink>
          <ButtonLink href="/" className="mr-2.5 text-content-primary" IconLeft={<HomeIcon />} variant="tertiary" />
        </div>
      </div>

      <div className="md:pt-[70px]">
        <div className="mx-auto px-[15px] max-w-[1500px] md:px-[30px]">{children}</div>
      </div>

      <div className="w-full flex flex-col absolute bottom-0 items-center pt-10 pb-4 bg-general-neutral-light">
        <div className="mb-8">
          <PoweredBy
            organisationName={ENV.NEXT_PUBLIC_ORGANISATION_NAME}
            strapline={true}
            linkUrl="https://in-part.com"
          />
        </div>

        <div className="border-t border-[#e5e7eb] pt-4 px-4">
          <p className="p-0 m-0 text-body-md font-normal leading-[1rem] text-[#9ca3af]">
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy" className="text-[#9ca3af] underline">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://policies.google.com/terms" className="text-[#9ca3af] underline">
              Terms of Service
            </a>{' '}
            apply.
          </p>
        </div>
      </div>
    </>
  );
};
