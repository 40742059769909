import { InputHTMLAttributes, forwardRef } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

type SearchInputProps = InputHTMLAttributes<HTMLInputElement> & {
  wrapperClassName?: string;
};

const inputStyles = `
transition-colors border bg-interaction-fill-active border-interaction-outline-enabled text-content-primary rounded-full p-2 pl-8 w-full h-[32px] text-body-md shadow-light-down
hover:bg-interaction-fill-hover hover:border-interaction-outline-hover
focus:bg-interaction-fill-active focus:border-interaction-outline-active focus:text-content-primary
disabled:bg-interaction-fill-disabled disabled:border-interaction-outline-disabled disabled:text-action-content-secondary-disabled
placeholder:text-body-md placeholder:text-content-secondary
placeholder-shown:bg-interaction-fill-search
`;
const iconStyles = `absolute left-2 top-2 h-4 w-4 text-content-secondary pointer-events-none peer-disabled:text-action-content-secondary-disabled`;

export default forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
  { className, wrapperClassName, ...props }: SearchInputProps,
  ref,
) {
  return (
    <div className={clsx('relative', wrapperClassName)}>
      <input ref={ref} className={clsx(inputStyles, 'peer', className)} {...props} />
      <MagnifyingGlassIcon className={iconStyles} />
    </div>
  );
});
