import { ReactNode } from 'react';
import {
  Dialog as HeadlessUiDialog,
  DialogPanel,
  DialogBackdrop,
  DialogProps as HeadlessUiDialogProps,
  Description,
  CloseButton,
} from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

const variantStyles = {
  informative: {
    className: `text-content-informative`,
    Icon: InformationCircleIcon,
  },
  success: {
    className: `text-content-positive`,
    Icon: CheckCircleIcon,
  },
  warning: {
    className: `text-content-warning`,
    Icon: ExclamationTriangleIcon,
  },
  negative: {
    className: `text-content-negative`,
    Icon: ExclamationCircleIcon,
  },
  delete: {
    className: `text-content-negative`,
    Icon: TrashIcon,
  },
};

type DialogProps = HeadlessUiDialogProps & {
  children?: ReactNode;
  actions?: ReactNode;
  variant: keyof typeof variantStyles;
  Icon?: ReactNode;
  panelClassName?: string;
};

function Dialog({ children, variant = 'informative', open, onClose, actions, panelClassName, ...props }: DialogProps) {
  const Icon = variantStyles[variant].Icon;

  return (
    <HeadlessUiDialog open={open} onClose={onClose} className="relative z-50" role="alertdialog" {...props}>
      <DialogBackdrop
        className="fixed inset-0 bg-black/30 duration-300 ease-out data-[closed]:opacity-0"
        transition
        {...props}
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center px-4">
        <DialogPanel
          className={clsx(
            'max-w-lg relative bg-white rounded-lg border border-general-neutral-light duration-300 ease-out data-[closed]:-translate-y-2 data-[closed]:opacity-0 shadow-dark-down overflow-hidden p-4',
            panelClassName,
          )}
          transition
        >
          <CloseButton className="flex-[0_0_1.5rem] hover:bg-black/5 rounded-sm absolute right-4 top-4">
            <XMarkIcon className="w-6 h-6 text-content-secondary" />
            <span className="sr-only">Close</span>
          </CloseButton>
          <div className="flex flex-col justify-center items-center mt-2 mx-4 gap-y-2">
            <Icon className={clsx('w-10 h-10', variantStyles[variant].className)} />
            <Description className="text-body-lg text-content-primary text-center">{children}</Description>
          </div>
          {actions && <div className="flex justify-between mt-2">{actions}</div>}
        </DialogPanel>
      </div>
    </HeadlessUiDialog>
  );
}

export default Dialog;
