export const ACCENT_COLOR_OPTIONS = [
  { label: 'Blue', value: '#7dbeff' },
  { label: 'Cyan', value: '#70e0e5' },
  { label: 'Green', value: '#73e5ac' },
  { label: 'Yellow', value: '#efeb9c' },
  { label: 'Orange', value: '#ffae70' },
  { label: 'Brown', value: '#b48b7d' },
  { label: 'Red', value: '#ff7373' },
  { label: 'Pink', value: '#f76f8e' },
  { label: 'Purple', value: '#8587ff' },
] as const;
