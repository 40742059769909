import ButtonContent, { BaseButtonProps, sharedStyles, sizeStyles, variantStyles } from './ButtonContent';
import clsx from 'clsx';

export default function ButtonA({
  variant = 'primary',
  size = 'md',
  children,
  IconRight,
  IconLeft,
  className,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement> & BaseButtonProps) {
  return (
    <a className={clsx(sharedStyles, variantStyles[variant], sizeStyles[size], className)} {...props}>
      <ButtonContent IconLeft={IconLeft} IconRight={IconRight}>
        {children}
      </ButtonContent>
    </a>
  );
}
